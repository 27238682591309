import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { media, Stack } from '@tymate/margaret';
import { fontStyles } from 'ui';
import Layout from 'components/Layout';
import ButtonLink from 'components/ButtonLink';

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing()};
`;

const MainColumn = styled(Stack).attrs({ direction: 'column', gutterSize: 1 })`
  flex: 1 1 300px;
  align-items: center;
`;

const Aside = styled.aside`
  flex: 1;
`;

const Header = styled(Stack).attrs({ direction: 'column', gutterSize: 1 })`
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;

const ContentBlock = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 1,
  size: 'full',
})`
  flex: 1;
  background-color: #fff;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

const ButtonLinks = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-right: -2px;
  > * {
    margin-left: 2px;
    margin-right: 2px;
  }

  ${media.desktop`
    margin-left: -${({ theme }) => theme.spacing(0.5)};
    margin-right: -${({ theme }) => theme.spacing(0.5)};
    > * {
      margin-left: ${({ theme }) => theme.spacing(0.5)};
      margin-right: ${({ theme }) => theme.spacing(0.5)};
    }
  `}
`;

const Title = styled.h1`
  ${fontStyles.h1Mega}
  font-family: ${({ theme }) => theme.fonts.title};
  margin-top: 0;
  margin-bottom: 0;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
`;

const BlockTitle = styled.h2`
  ${fontStyles.h2}
  font-family: ${({ theme }) => theme.fonts.title};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
`;

const Description = styled.div`
  align-self: center;
  ${fontStyles.bodyLarge}
  font-family: ${({ theme }) => theme.fonts.body};
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLighter};
  text-align: center;
`;

const GoBackButton = styled(ButtonLink)`
  background-color: ${({ theme }) => theme.red};
  color: #fff;
  margin-top: ${({ theme }) => theme.spacing()};
`;

const SmallText = styled.div`
  ${fontStyles.bodySmall}
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.textLighter};
  text-align: center;
`;

const ParkLink = styled(Link)`
  ${fontStyles.body}
  color: ${({ theme }) => theme.red};
  text-decoration: underline;
  margin-left: 0;
  margin-right: 0;
`;

const WrapperLogo = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
`;

const Page404 = ({ data }) => {
  const dataBlocks = data?.allDatoCmsPageNotFound?.nodes || [];

  return (
    <Layout variant="home">
      <Wrapper>
        <Aside />
        <MainColumn>
          <Header>
            <Title>PAGE 404</Title>
            <WrapperLogo>
              <StaticImage
                src="../images/svg/koezio-black.svg"
                alt="koezio"
                placeholder="blurred"
                layout="fixed"
                width={302}
                height={45}
              />
            </WrapperLogo>
          </Header>
          {dataBlocks.map(
            ({
              title,
              description,
              ctaName,
              linkDescription,
              parks,
              locale,
            }) => (
              <ContentBlock>
                <BlockTitle>{title}</BlockTitle>
                <Description>{description}</Description>
                <GoBackButton
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    navigate('/');
                  }}
                >
                  {ctaName}
                </GoBackButton>
                <SmallText>{linkDescription}</SmallText>
                <ButtonLinks>
                  {parks.map(({ name, slug }, index) => {
                    const suffix = locale === 'fr' ? '' : locale;
                    const href = Boolean(suffix)
                      ? `/${suffix}/${slug}`
                      : `/${slug}`;

                    return (
                      <>
                        <ParkLink href={href}>
                          {name.replace(/^koezio./i, '').trim()}
                        </ParkLink>
                        {index !== parks.length - 1 && <SmallText>-</SmallText>}
                      </>
                    );
                  })}
                </ButtonLinks>
              </ContentBlock>
            ),
          )}
        </MainColumn>
        <Aside />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDatoCmsPageNotFound {
      nodes {
        title
        description
        ctaName
        linkDescription
        locale
        parks {
          name
          slug
        }
      }
    }
  }
`;

export default Page404;
